.btn {
    width: 100% !important;
    height: 54px !important;
    border: 0px solid transparent !important;
    border-bottom: 1px solid #aaa !important;
    padding: 10px !important;
    outline: none !important;
    margin: 1.5em auto !important;
    transition: all .5s ease !important;
    box-shadow: 0 0 5px rgba(0, 80, 80, .4) !important;
    border-radius: 10px !important;
    background-color: #0bd7ee !important;
    color: #051315 !important;
    cursor: pointer !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.btn:hover,
.btn:focus {
    background-color: #0bd7ee !important;
    color: #fff !important;
}

.back-button {
    width: auto !important;
    border: transparent !important;
    background-color: transparent !important;
    color: white !important;
    box-shadow: none !important;
    margin: auto !important;
}

.back-button:hover,
.back-button:focus {
    background-color: transparent !important;
}

.margin-left-10 {
    margin-right: 10px;
}

.vertical-align {
    vertical-align: -0.5em;
}