.profile-section {
    width: 657px !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid #705e5e;
    border-left: 0px;
    background-color: #142123;
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
}

.inches-boxes {
    display: flex;
    gap: 30px;
}

.center-text {
    display: flex;
    justify-content: center;
    text-align: center;
}


.modal-title-text {
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;

    color: #041214;
}

.modalDetail {
    font-family: 'BentonSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #727A7B;
}

.MuiButtonBase-root.MuiButton-root.next-button {
    float: right;
    height: 54px !important;
    width: 162px !important;
    padding: 0px !important;
    margin: 0px !important;
    margin-top: 5px !important;
}

.MuiModal-root {
    overflow: auto;
}

@media screen and (max-width:940px) {
    .profile-section {
        width: auto !important;
    }
}

// @media screen and (max-height:720px) {
//     .MuiBox-root {
//         margin-top: 151px;
//         margin-bottom: 151px;
//     }
// }

@media screen and (max-width:540px) {
    .next-button {
        float: none;
        margin-top: 10px !important;
        width: 100% !important;
        text-align: center;
    }

    .inches-boxes {
        display: block;
    }

    .profile-section {
        padding: 20px;
    }

    .success-modal {
        width: 70% !important;
    }
}

.delete-success-modal {
    width: auto !important;
}

.MuiFormControl-root.MuiTextField-root {
    margin-top: 5px !important;
    margin-bottom: 15px !important;
}

.MuiFormGroup-root {
    margin-top: 5px !important;
    margin-bottom: 7px !important;
}

.MuiFormControl-root.MuiTextField-root {
    margin-top: 5px !important;
    margin-bottom: 15px !important;
}

.MuiButtonBase-root.MuiButton-root {
    padding: 6px 30px !important;
}

.modal-button {
    height: 54px !important;
}

.age-drop-down {
    color: #fff !important;
    border-radius: 10px !important;
    background-color: #051214 !important;
    border: 1px solid #364242 !important;
    margin-top: 5px;
    margin-bottom: 15px;
    width: 310px;
}

.MuiOutlinedInput-root.Mui-focused {
    border-color: #FAAA2F !important;
}

.questions-list__modal_next.MuiButtonBase-root.MuiButton-root {
    width: 112px !important;
    margin: unset !important;
    margin-left: auto !important;
    margin-top: auto !important;
    padding: 2px 10px !important;
    height: 43px !important;
    text-transform: none !important;
}

@media screen and (max-width:600px) {
    .login-container {
        .toogle-button-group {
            width: 100%;
        }

        .age-drop-down {
            width: 100%;
        }

        .toogle-button-group__single-group {
            display: flex;
            flex: 1;
        }

        .MuiButtonBase-root.MuiButton-root.next-button {
            margin-top: 40px !important;
        }
    }
}