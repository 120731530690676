.toogle-button-group {
    background-color: #041214 !important;
    margin: 2px;
    border-radius: 10px !important;
    border: 2px solid #273334;

    width: 304px;
    height: 56px;
    align-items: center;

    &__single-group {
        background-color: #041214 !important;
        color: #fff !important;
        margin-left: 3px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        border-radius: 5px !important;
        border-left: 2px solid #273334 !important;
        border-radius: 0px !important;

        width: 97px;
        height: 46px;
    }

    &__active-group {
        background-color: #faaa2f !important;
        color: #000 !important;
        border-radius: 5px !important;
    }

    :first-child {
        border-left: 0px !important;
    }
}

.MuiButtonBase-root.MuiToggleButton-root {
    padding: 4px 20px !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
}