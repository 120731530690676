.textbox-size {
    height: auto !important;
    width: 100%;
    color: #fff !important;

}

.margin-0 {
    margin: 0px !important;
    margin-top: 6px !important;
}

.qus-text-box {
    display: flex !important;
    align-items: flex-end !important;

    .MuiFormControl-root.MuiTextField-root {
        margin-bottom: 0px !important;
    }
}

.ok-button {

    &--deactive {
        background-color: #727A7B !important;
        color: #fff !important;
        font-size: 15px !important;
        margin: 0px !important;
    }

    &--active {
        background-color: #0BD7EE !important;
        color: #000 !important;
        font-size: 15px !important;
        margin: 0px !important;
    }
}