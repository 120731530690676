.flex-center {
    align-items: center;
    justify-content: center;
    display: flex;
}

.or-condition {
    text-align: center;
    margin: 0px;
    padding: 0px;
}

.text-center {
    text-align: center;
}

.company__info {
    width: 100%;
    height: 100%;
    position: relative;
}

@media screen and (max-width: 640px) {
    .main-content {
        width: 90%;
    }

    .company__info {
        display: none;
    }

}

@media screen and (min-width: 642px) and (max-width:800px) {
    .main-content {
        width: 70%;
    }
}

form {
    padding: 0 2em;
}

.ant-checkbox-inner::after {
    width: 13.714286px;
    height: 23.142857px;
}

.bottom-section {
    justify-content: flex-end;
}

.sign-up-welcome {
    cursor: pointer;
}

.bottom-signup p {
    margin: 0px !important;
}

.MuiButtonBase-root.MuiButton-root.getOtp-btn {
    margin: 16px 0px !important;
    height: 54px !important;
}

.PhoneInput .PhoneInput {
    color: white;
    border-radius: 10px;
    background-color: #041214;
    flex: 1;
    padding: 5px 5px;
    border: 1px solid #364242;

    &.PhoneInput--focus {
        outline: #FAAA2F solid 1px;
    }

    /* .PhoneInput {
        padding: unset;
        border: unset;
    } */

    /* input::placeholder {
        color: white;
    } */

    .PhoneInputCountry {
        display: flex;
        align-items: center;
        background-color: #202c2e;
        align-items: center;
        padding: 13px 10px;
        border-radius: 5px;

        .PhoneInputCountrySelectArrow {
            margin-left: 13px;
            border-bottom-width: 2px;
            border-right-width: 2px;
            opacity: 1;
        }
    }

    input:focus {
        outline: unset;
    }

    input {
        padding-left: 8px;
        color: white;
        background-color: transparent;
        border: none;
        height: 1.4375em;
        padding: 5px 4px;
        font-weight: 600;
        font-size: 1rem;
        text-emphasis: inherit;
    }
}

.PhoneInputCountryIcon--border {
    box-shadow: unset !important;
}

.cus-phone-input {
    margin-bottom: 6px;
    margin-top: 14px;
}

.input-error {
    color: red;
    font-size: smaller;
    font-weight: 400 !important;
    display: block;
}

.remember-container {
    margin-top: 16px;
}