.country-option {
  background-color: #041214 !important;
  color: #fff !important;
  position: relative;
  height: 30px;
  border-radius: 0px !important;

  /* position: absolute !important;
  z-index: 9;
  bottom: 27px;
  left: 14px; */
}

#countryOption {
  display: flex;
  align-items: center;
  background-color: #202c2e;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -7px;
  min-width: 70px;

  svg {
    height: 30px !important;
    width: 30px !important;
    justify-content: center;
    border-radius: 10px;
  }

  .svg-holder {
    height: 35px;
    width: 35px;
    align-items: center;
    min-width: 0;
  }
}
