.question-option{
    font-size: 16px !important;
}

.cus-radio-parent {
    .MuiButtonBase-root {
        padding-bottom: unset !important;
        padding-top: 4px !important;
    }
    
    .MuiFormControlLabel-root {
        margin-bottom: 10px;
        -webkit-align-items: self-start;
        -webkit-box-align: start;
        align-items: self-start;
    }
}