.select-option {
    background-color: #041214 !important;
    color: #fff !important;
    border-left: 1px solid #273334;
    position: relative;
    height: 30px;
    border-radius: 0px !important;
}

.menu-item {
    background-color: #fff !important;
    color: #000 !important;

    :focus {
        background-color: #0BD7EE !important;
        color: #041214 !important;
    }
}

.selected-item {
    background-color: #0BD7EE !important;
    color: #041214 !important;
}

.MuiList-root.MuiMenu-list{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}