.header-section {
    width: 100%;
    align-items: center;
    text-align: center;
    background-color: #051315;
    position: relative;
    padding-top: 100px;
}

@media screen and (max-width: 540px) {
    .header-section {
        padding-top: 40px;
    }
}