body {
    margin: 0px;
}

#root {
    margin: 0;
}

.login-container {
    height: 100%;
    width: 100%;
    background-color: #051315;
    padding-top: 50px;
    padding-bottom: 100px;
    min-height: 610px;

    .company-logo {
        width: 100%;
    }
}

.text-center {
    text-align: center;
}

.main-content {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .4);

    display: flex;
}

.out-box-wrap {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.fa-android {
    font-size: 3em;
}

.login_form {
    width: auto !important;
    height: auto !important;
    min-height: 94.7%;
    background-color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 0px;
    width: 50%;
    background-color: #142123;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 50px;
    position: relative;
}

.left-side-box {
    width: 50%;
    background-image: url("../../assets/images/left-img.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
}

@media screen and (max-width: 767px) {
    .left-side-box {
        display: none;
    }

    .right-side-box {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .main-content {
        width: 90%;
    }

    .login_form {
        padding: 20px;
        position: relative;
    }
}
@media screen and (max-width: 640px) {
    

    .login_form {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
}

@media screen and (min-width: 642px) and (max-width:800px) {
    .main-content {
        width: 70%;
    }
}

form {
    padding: 0 2em;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.title {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 12px;
    color: white;

    @media screen and (max-width: 992px) {
        line-height: 25px;
    }
}

.text-right {
    display: flex;
    justify-content: flex-end;
}

.text-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'BentonSans';
    font-weight: 500;
    font-size: 16px;

    @media screen and (max-width: 992px) {
        display: block;
    }
}

.white {
    color: #fff;
}

.ab-inner-image-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100px;
}

img {
    max-width: 100%;
}

.ab-bg-image {
    position: absolute;
    left: 0px;
    width: 100%;
    z-index: 0;
    top: 10px;

    img {
        width: 100%;
    }
}

@media screen and (max-width: 540px) {

    .login-container {
        padding-bottom: 50px;
        padding-top: 30px;
    }

    .ab-bg-image {
        overflow: hidden;

        img {
            width: 200%;
            max-width: none;
        }
    }
}

@media screen and (max-width: 992px) {

    .out-box-wrap {
        margin: 10px !important;
    }
}