.bottom-section {
    justify-content: flex-end;
}
.MuiButtonBase-root.MuiButton-root.assignment-btn {
    padding: 12px 0px !important;
    font-size: 14px !important;
}
.otpTextBox {
    margin-bottom: 0px !important;
}

.back-left-wrap {
    position: absolute;
    right: 10px;
    display: block;
    top: 10px;
    z-index: 100;
    background-color: #0BD7EE;
    // width: 16px;
    padding: 10px;
    border-radius: 10px;

    img {
        width: 100%;
        float: left;
    }
}
